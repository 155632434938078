<template>
	<div id="userList">
        <div class="tips">
            <p style="color: #E74C3C;">温馨提示：员工登录请使用 “(用户名/手机号) @{{ master_name }}” 进行登录</p>
        </div>

        <div class="el-content mb12">
            <div class="kd-pacel">检索</div>
            <a-space>
                <a-input-search 
                    v-model:value="name" 
                    style="width: 300px" 
                    placeholder="姓名" 
                    enter-button 
                    @search="getUserList(1, info.limit)"
                />
                <a-button type="primary" 
                    v-has="{ action: '/admin/employee/employee_info' }" 
                    @click="editEmployeeShow"
                >
                    <i class="ri-add-line ri-top"></i>
                    添加员工
                </a-button>
            </a-space>
        </div>

        <div class="el-content">
            <div class="kd-pacel">列表</div>
            <a-table :pagination="false" row-key="user_id" :data-source="info.list" :columns="[
                {title:'ID',dataIndex:'user_id'},
                {title:'手机号',dataIndex:'mobile', slots: { customRender: 'mobile' }},
                {title:'用户名',dataIndex:'name', slots: { customRender: 'name' }},
                {title:'状态',dataIndex:'status', slots: { customRender: 'status' }},
                {title:'创建时间',dataIndex:'create_time'},
                {title:'最后登录时间',dataIndex:'login_time'},
                {title:'最后登录IP',dataIndex:'last_login_ip'},
                {title:'操作',dataIndex:'action', slots: { customRender: 'action' }},
            ]" >
                <template #mobile="{record}">
                    <span>{{ record.mobile }}@{{ master_name }}</span>
                </template>
                <template #name="{ record }">
                    <span>{{ record.name }}@{{ master_name }}</span>
                </template>
                <template #status="{ record }">
                    <a-tag :color="record.status == 1 ? '#00CC66':'#FF6633'">{{ record.status ? "正常":"已禁用" }}</a-tag>
                </template>
                <template #action="{ record }">
                    <a-space>
                        <router-link v-if="record.status == 1"  
                            :to="{ path: '/admin/employee/employee_info', query: { user_id: record.user_id } }"
                        >
                            <kd-button title="编辑" 
                                icon="ri-edit-circle-line" 
                                v-has="{ action: '/admin/employee/employee_info' }">
                            </kd-button>
                        </router-link>
                        <kd-button title="禁用" 
                            type="danger"
                            icon="ri-forbid-line" 
                            v-if="record.status == 1" 
                            v-has="{ action: 'sass_employee_stop' }"
                            @change="deletUser(record, 0)">
                        </kd-button>
                        <kd-button title="启用" 
                            type="success"
                            icon="ri-restart-line" 
                            v-else
                            v-has="{ action: 'sass_employee_stop' }"
                            @change="deletUser(record, 1)">
                        </kd-button>
                        <kd-button title="删除" 
                            type="danger"
                            icon="ri-delete-bin-line" 
                            v-if="!record.status"
                            v-has="{ action: 'sass_employee_stop' }"
                            @change="deletUser(record, 2)">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
            <div class="pager">
                <a-pagination 
                    show-size-changer 
                    v-model:current="info.page" 
                    v-model:pageSize="info.limit" 
                    :total="info.count" 
                    @showSizeChange="(p,e)=>{getUserList(info.page,e)}"
                    @change="(e)=>getUserList(e,info.limit)"
                />
            </div>
        </div>

		<a-modal title="添加/编辑员工" 
            width="500px"
            v-model:visible="employeeState.show" 
            @ok="saveEmployee" 
            @cancel="employeeState.show = false" 
        >
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}" v-if="employeeState.form">
				<a-form-item label="用户名">
					<a-input v-model:value="employeeState.form.name" :addon-after="'@'+$store.state.username"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="employeeState.form.mobile" addon-before="+86"></a-input>
				</a-form-item>
				<a-form-item label="密码"><a-input v-model:value="employeeState.form.password"></a-input></a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRaw, toRefs } from 'vue';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				limit: 10,
				count: 0
			},
			name: '',
			master_name: ''
		})

		getUserList(1,state.info.limit)

        let empState = useEditEmployee({state,getUserList})

		function getUserList(page,limit){
			authModel.getEmployee(page,limit,{keyword:state.name},res=>{
				state.info.list = res.data;
				state.info.page = res.page.currentPage;
				state.info.count = res.page.totalCount;
				state.master_name = res.master_name;
			})
		}
		const deletUser = (e,status)=>authModel.changeEmployeeStatus(e.user_id,status,()=>{
			getUserList(state.info.page, state.info.limit)
		})

		return{
			...toRefs(state),
			getUserList,
			deletUser,
            ...empState
		}
	}
}


//新增员工
function useEditEmployee(obj){
    let employeeState = reactive({
        show:false,
        form:null
    })

    function editEmployeeShow(){
        employeeState.form = {
            name: '',
            mobile: '',
            password: ''
        }
        employeeState.show = true
    }

    function saveEmployee(){
        authModel.addOrEditEmployee('add',toRaw(employeeState.form),()=>{
			obj.getUserList(obj.state.info.page, obj.state.info.limit);
			employeeState.show = false
		})
    }
    return{ employeeState,editEmployeeShow ,saveEmployee }
}


</script>

<style lang="scss">

</style>
